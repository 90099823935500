/** @jsxRuntime classic */
/** @jsx jsx */

import { ReactNode } from "react";
import { jsx, css } from "@emotion/react";
import mediaQuery from "src/utils/mediaQuery";
import { Alert, Colors, Link, Text } from "@zapier/design-system";
import { createAppLocalStorage } from "src/utils/app-local-storage";

const styleContainer = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content max-content max-content max-content;
  grid-template-areas:
    "Nav Nav Nav"
    "Sidebar Sidebar Sidebar"
    "Main Main Main"
    "Main Main Main";
  gap: 0;
  position: relative;
  height: 100vh;

  background: ${Colors.neutral100};

  ${mediaQuery.large} {
    grid-template-columns: 405px 1fr 1fr;
    grid-template-rows: max-content 1fr 1fr 300px;
    grid-template-areas:
      "Nav Nav Nav"
      "Sidebar Main Main"
      "Sidebar Main Main"
      "Sidebar Main Main";
  }
`;

const styleSidebar = css`
  position: relative;
  z-index: 2;
  grid-area: Sidebar;
  background: ${Colors.neutral100};
`;

const styleMain = css`
  position: relative;
  z-index: 1;
  grid-area: Main;
  background: ${Colors.neutral100};

  ${mediaQuery.large} {
    overflow-y: scroll;
  }
`;

const styleAlertBanner = css`
  padding: 20px 20px 0;
`;

const styleHeader = css`
  z-index: 3;
  grid-area: Nav;
`;

interface LayoutProps {
  showAlert?: boolean;
  header?: ReactNode;
  aside: ReactNode;
  main: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  header,
  aside,
  main,
  showAlert = false,
}) => {
  const storage = createAppLocalStorage({
    key: "alert_dismiss",
    initialValue: false,
  });
  const [alertDismiss, setAlertDismiss] = storage.useStorage();

  return (
    <div css={styleContainer}>
      <div css={styleHeader}>{header}</div>
      <aside css={styleSidebar}>{aside}</aside>
      <main css={styleMain}>
        {showAlert && !alertDismiss && (
          <div css={styleAlertBanner}>
            <Alert
              layout="banner"
              title="We have a new solution that delivers a better experience for your users!"
              type="info"
              onDismiss={() => {
                setAlertDismiss(true);
              }}
            >
              <Text color="white" fontWeight={700} tag="p">
                We recommend using that instead of this generator. Try the{" "}
                <Link href="/partner/solutions/plug-and-play">
                  Full Zapier Experience
                </Link>
                .
              </Text>
            </Alert>
          </div>
        )}
        {main}
      </main>
    </div>
  );
};

export default Layout;
