export function clickOnSpace(event: React.KeyboardEvent): void {
  // Buttons are supposed to react to Space as well as Enter, so we need to
  // trigger a click through JS
  if (
    event.key === " " &&
    // Prove to TypeScript that the `click` method exists on this event target
    event.target instanceof HTMLElement
  ) {
    event.preventDefault();
    event.target.click();
  }
}
