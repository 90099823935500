import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

interface AppLocalStorage<T> {
  get: (fallback: T) => T;
  set: (value: T) => void;
  useStorage: () => [T, Dispatch<SetStateAction<T>>];
}

// This is _essentially_ a class, but the ESLint React hooks plugin isn't smart
// enough to know it's not a React component, so it complains if we use hooks
// inside a class. If it's all functions, though, no big deal lol.
export function createAppLocalStorage<T>({
  prefix = "zapier.partner.",
  key,
  initialValue,
}: {
  prefix?: string;
  key: string;
  initialValue: T;
}): AppLocalStorage<T> {
  const scopedKey = prefix + key;
  // Server-side rendering workaround
  const storage: Storage | undefined =
    typeof localStorage !== "undefined" ? localStorage : undefined;

  function get(): T {
    if (!storage) {
      return initialValue;
    }
    const value = storage.getItem(scopedKey);
    if (value === null) {
      return initialValue;
    }
    return JSON.parse(value);
  }

  function set(value: T): void {
    if (!storage) {
      return;
    }
    storage.setItem(scopedKey, JSON.stringify(value));
  }

  function useStorage(): [T, Dispatch<SetStateAction<T>>] {
    const [state, setState] = useState<T>(initialValue);
    const hasLoadedRef = useRef(false);

    useEffect(() => {
      if (hasLoadedRef.current) {
        set(state);
      } else {
        setState(get());
        hasLoadedRef.current = true;
      }
    }, [state]);

    return [state, setState];
  }

  return { get, set, useStorage };
}
