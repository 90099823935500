// Mobile first perspective
export const mediaQueryExpr = {
  small: "(min-width: 520px)",
  medium: "(min-width: 770px)",
  large: "(min-width: 1030px)",
  darkMode: "(prefers-color-scheme: dark)",
} as const;

const mediaQuery = {
  small: `@media ${mediaQueryExpr.small}`,
  medium: `@media ${mediaQueryExpr.medium}`,
  large: `@media ${mediaQueryExpr.large}`,
  darkMode: `@media ${mediaQueryExpr.darkMode}`,
} as const;

export default mediaQuery;
