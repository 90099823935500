/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Button, Colors, Text, Tooltip } from "@zapier/design-system";
import { ReactNode, useState } from "react";
import { sleep } from "src/utils/sleep";

const styleInline = css({ display: "inline" });

const styleContainer = css({
  margin: 0,
  display: "grid",
  gap: 10,
});

const styleCode = css({
  lineHeight: 1.5,
  borderRadius: 10,
  background: Colors.gray,
  color: Colors.gray10,
  whiteSpace: "pre-wrap",
  wordBreak: "break-all",
  padding: 10,
  fontSize: "1rem",
  margin: 0,
});

const styleCopyContainer = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
});

const styleButtonContainer = css({
  position: "relative",
  marginLeft: 10,
});

interface CodeBlockProps {
  code: string;
  "data-testid"?: string;
  title?: ReactNode;
  showCopyButton?: boolean;
}

export default function CodeBlock({
  code,
  "data-testid": dataTestId,
  title = "",
  showCopyButton = false,
}: CodeBlockProps) {
  const [copyMessage, setCopyMesage] = useState("");
  return (
    <figure css={styleContainer}>
      <span css={styleCopyContainer}>
        {title && (
          <figcaption css={styleInline}>
            <Text fontWeight={600}>{title}</Text>
          </figcaption>
        )}
        {showCopyButton && (
          <span css={styleButtonContainer}>
            {copyMessage && <Tooltip position="west">{copyMessage}</Tooltip>}
            <Button
              size="xsmall"
              color="secondary"
              iconBefore="actionCopy"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(code);
                  setCopyMesage("Copied to clipboard");
                } catch (err) {
                  setCopyMesage("Failed to copy");
                } finally {
                  await sleep(5 * 1000);
                  setCopyMesage("");
                }
              }}
            >
              Copy
            </Button>
          </span>
        )}
      </span>
      <pre data-testid={dataTestId} css={styleCode}>
        {code}
      </pre>
    </figure>
  );
}
