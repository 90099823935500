/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from "@emotion/react";
import { Alert, AlertDescription, Text } from "@zapier/design-system";

export function QuacCodeAlert() {
  return (
    <Alert title="Enable Quick Account Creation for your users">
      <AlertDescription maxLines={100}>
        <p>
          Replace the highlighted fields with your user&apos;s{" "}
          <Text fontWeight={600}>email</Text>,{" "}
          <Text fontWeight={600}>first name</Text>, and{" "}
          <Text fontWeight={600}>last name</Text> to enable Quick Account
          Creation and bypass Zapier sign-up for your users.
        </p>

        <p>
          Note: If the user information is not provided, the{" "}
          <Text fontWeight={600}>
            code below as is without modification will still work
          </Text>{" "}
          but your users will need to sign up to Zapier before creating a Zap.
        </p>
      </AlertDescription>
    </Alert>
  );
}
