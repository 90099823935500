/** @jsxRuntime classic */
/** @jsx jsx */

// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
//
// Please test this code sample in Codesandbox when updating it!
//
// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

import { jsx } from "@emotion/react";
import { Text } from "@zapier/design-system";
import { Fragment } from "react";
import CodeBlock from "src/components/CodeBlock";
import { kebabToCamelCase } from "src/utils/kebabToCamelCase";
import {
  AppParamsPlaceholder,
  CodeExampleProps,
  styleExampleLayout,
} from "./CodeExample";
import { CodeBlockWithPlaceholders } from "../CodeBlockWithPlaceholders";
import { QuacCodeAlert } from "./QuacCodeAlert";
import { CodePlaceholder } from "./CodePlaceholder";

export function CodeExampleVue({ params, element }: CodeExampleProps) {
  return (
    <div css={styleExampleLayout}>
      <Text tag="h2" type="sectionHeader3">
        A solution for Vue.js 2
      </Text>
      <Text tag="p">
        Custom elements (web components) can be used with Vue.js. Use the{" "}
        <strong>main.js</strong> and <strong>App.vue</strong> snippets as
        examples.
      </Text>
      <CodeBlock
        title="HTML (<head>)"
        code={`\
<!-- See the "Vanilla JS" solution if you'd rather use JS to load these -->
<script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"></script>
<link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"/>
`}
        showCopyButton={true}
      />
      <CodeBlockWithPlaceholders
        data-testid="codeBlock:body"
        title="App.vue"
        alert={<QuacCodeAlert />}
      >
        {`\
<template>
  <div>
    <h1>Zapier Elements Vue</h1>
    <${element}
`}
        {[...params.entries()]
          .filter(([key]) => key !== "id")
          .map(([key, val]) => {
            const indent = " ".repeat(6);
            const camelKey = kebabToCamelCase(key);
            if (val instanceof AppParamsPlaceholder) {
              return (
                <Fragment key={key}>
                  {indent}
                  {":"}
                  {camelKey}
                  {".prop='"}
                  <CodePlaceholder value={val} />
                  {"'\n"}
                </Fragment>
              );
            }
            return `${indent}:${camelKey}.prop='${JSON.stringify(val)}'\n`;
          })}
        {`\
    ></${element}>
  </div>
</template>

<script>
  export default {
    name: "App",
    components: {},
  };
</script>
`}
      </CodeBlockWithPlaceholders>
      <CodeBlock
        title="main.js"
        code={`\
import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
// Allow \`<${element}>\` custom element
// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
Vue.config.ignoredElements = ["${element}"];

new Vue({ render: (h) => h(App) }).$mount("#app");
`}
        showCopyButton={true}
      />
    </div>
  );
}
