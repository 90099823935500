/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Button, Colors, Text, Tooltip } from "@zapier/design-system";
import { ReactNode, useRef, useState } from "react";
import { sleep } from "src/utils/sleep";

const styleInline = css({ display: "inline" });

const styleContainer = css({
  margin: 0,
  display: "grid",
  gap: 10,
});

const styleCode = css({
  lineHeight: 1.5,
  borderRadius: 10,
  background: Colors.gray,
  color: Colors.gray10,
  whiteSpace: "pre-wrap",
  wordBreak: "break-all",
  padding: 10,
  fontSize: "1rem",
  margin: 0,
});

const styleCopyContainer = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
});

const styleButtonContainer = css({
  position: "relative",
  marginLeft: 10,
});

interface CodeBlockWithPlaceholdersProps {
  children: ReactNode;
  alert?: ReactNode;
  "data-testid"?: string;
  title: ReactNode;
}

export function CodeBlockWithPlaceholders({
  children,
  alert,
  "data-testid": dataTestId,
  title = "",
}: CodeBlockWithPlaceholdersProps) {
  const [copyMessage, setCopyMesage] = useState("");
  const preRef = useRef<HTMLPreElement>(null);
  return (
    <figure css={styleContainer}>
      {alert}
      <span css={styleCopyContainer}>
        <figcaption css={styleInline}>
          <Text fontWeight={600}>{title}</Text>
        </figcaption>

        <span css={styleButtonContainer}>
          {copyMessage && <Tooltip position="west">{copyMessage}</Tooltip>}
          <Button
            size="xsmall"
            color="secondary"
            iconBefore="actionCopy"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(
                  preRef.current?.textContent ?? "",
                );
                setCopyMesage("Copied to clipboard");
              } catch (err) {
                setCopyMesage("Failed to copy");
              } finally {
                await sleep(5 * 1000);
                setCopyMesage("");
              }
            }}
          >
            Copy
          </Button>
        </span>
      </span>
      <pre data-testid={dataTestId} css={styleCode} ref={preRef}>
        {children}
      </pre>
    </figure>
  );
}
